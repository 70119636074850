import Picture from '../components/Picture';
import reshetov from '../assets/img/team/reshetov.jpg';
import bacanova from '../assets/img/team/bacanova.jpg';
import surkov from '../assets/img/team/surkov.jpg';
import kruchinina from '../assets/img/team/kruchinina.jpg';

import '../assets/css/our-works.css';

export default function About() {
    return (
        <div id="about" className='about'>
            <h1>О НАС</h1>
            <div className="about-content">
                <div className="description">
                    <div className='text'>
                        Наша компания - это коллектив талантливых художников и флористов, которые создают удивительные картины из живых цветов. Мы убеждены, что цветы являются одним из самых прекрасных и вдохновляющих природных материалов, поэтому мы стараемся передать их красоту и изящество в каждой работе.
                    </div>
                    <div className="mobile-hidden text">
                        Каждая наша картина уникальна и выполнена вручную с любовью и вниманием к деталям. Мы используем только свежие и качественные цветы, чтобы гарантировать долговечность и яркость наших произведений.
                    </div>
                    <div className="text">
                        Наши работы станут прекрасным украшением для вашего дома или офиса, а также оригинальным подарком для близких вам людей. Мы можем создать картины на любую тему и в любом стиле, полностью подстраиваясь под ваши пожелания.
                    </div>
                </div>
                <div className="mobile-hidden logo"></div>
            </div>
            <div className="team">
                <Picture img={reshetov} name="Решетов Семён" description="Main-Developer" style={{ alignSelf: "flex-start", flexDirection: "row" }} />
                <Picture img={bacanova} name="Бацанова Екатерина" description="<div>Продакт-менеджер, <br />Сейл</div>" style={{ alignSelf: "flex-end", flexDirection: "row-reverse" }} />
                <Picture img={surkov} name="Сурков Дмитрий" description="<div>Бизнес-аналитик, <br />Технический писатель</div>" style={{ alignSelf: "flex-start", flexDirection: "row" }} />
                <Picture img={kruchinina} name="Кручинина Дарья" description="<div>Дизайнер, <br />Проджект-менеджер</div>" style={{ alignSelf: "flex-end", flexDirection: "row-reverse" }} />
            </div>
        </div>
    );
}