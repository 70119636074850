import './components/Router'
import Header from './App/Header';
import OurWorks from './App/OurWorks';
import About from './App/About';

import './assets/css/App.css'
import Technology from './App/Technology';
import Order from './App/Order';
import Delivery from './App/Delivery';
import Contacts from './App/Contacts';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main">
        <OurWorks/>
        <About />
        <Technology />
        <Order />
        <Delivery />
        <Contacts />
      </div>
    </div>
  );
}

export default App;
