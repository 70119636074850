export default function NavigationBar() {
	return (
      <div className="header">
        <a href="#about"><button className="button">О НАС</button></a>
        <a href="#order"><button className="button">ORDER NOW</button></a>
		<a href="#delivery"><button className="button">ДОСТАВКА</button></a>
		<a href="#contacts"><button className="button">КОНТАКТЫ</button></a>
      </div >
    )
}
