import '../assets/css/order.css';

export default function Order() {
    const openPopup = () => {
        document.getElementById('popup').style.display = 'block';
        document.body.style.overflow = 'hidden';
    };
    
    const closePopup = () => {
        document.getElementById('popup').style.display = 'none';
        document.body.style.overflow = 'auto';
    };
    return (
        <div id="order" className='order'>
            <h1>СДЕЛАТЬ ЗАКАЗ ОНЛАЙН</h1>
            <button onClick={openPopup} className='openPopupbtn'>Открыть окно для заказа</button>
            <div className="popup" id="popup">
                <div className="popup-content">
                    <span className="close" onClick={closePopup}>&times;</span>
                    <h2>Заказать картину из цветов в Blooming Art</h2>
                    <p>Заказать картину из цветов можно по номеру телефона: <a href="tel:88005553535">8(800)555-35-35</a></p> 
                    <p>Обсудим форму, дизайн картины, учтём все Ваши пожелания по изготовлению!</p>
                </div>
            </div>       
        </div>
    )
}
