import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import NotFound from "./NotFound";
import App from "../App";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/" element={<App />} />
            </Routes>
        </BrowserRouter>
    );
}
