import '../assets/css/delivery.css';
import DeliveryBlock from '../components/Delivery-block';

export default function Delivery() {
    return (
        <div id="delivery" className="delivery">
            <h1>ДОСТАВКА</h1>
            <div className="delivery-blocks">
                <DeliveryBlock title="CDEK" description="Воспользовавшись услугой СДЭК, вы сможете успешно получить наши цветочные шедевры. Сервис предлагает различные варианты доставки: прямо к вашему порогу или в пункте выдачи заказов. В крупные города доставка осуществляется в течение 1–3 рабочих дней, а в регионы — до недели. У вас будет возможность отслеживать периоды доставки вашего заказа с помощью уникального номера отслеживания, выдаваемого после отправки." img="" />
                <DeliveryBlock title="Почта России" description="Наши картины могут быть доставлены Почтой России в любую точку страны. В среднем время доставки варьируется от 3 до 14 дней в зависимости от региона, в котором вы находитесь. Мы поддерживаем два варианта для наших клиентов: (1) обычная почтовая доставка; (2) экспресс-доставка, если вы хотите получить свой заказ быстрее. В обоих случаях мы предоставляем номер отслеживания, чтобы вы могли наблюдать за ходом доставки." img="" />
                <DeliveryBlock title="Яндекс.Доставка" description="Благодаря сервису Яндекс.Доставка наши картины быстро и успешно попадают в руки заказчиков в крупных городах России. Доставка возможна как в течении нескольких часов, так и в течении двух дней в зависимости от вашего гео-расположения. Сервис дает возможность клиенту выбрать удобное время и место получения заказа при оформлении. Отслеживание доставки в реальном времени также предусмотрено." img="" />
            </div>            
        </div>
    )
}
