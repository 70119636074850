import '../assets/css/delivery.css';

export default function DeliveryBlock({ title, description }) {
    return (
        <div className='delivery-block'>
            <h3>{ title }</h3>
            <div className="delivery-description">{ description }</div>
        </div>
    )
}
