import { Slide } from 'react-slideshow-image';
import { useState, useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css'

// import 'react-slideshow-image/dist/styles.css'

import Picture from '../components/Picture';
import pictureImage0 from '../assets/img/ourWorks/1.PNG';
import pictureImage1 from '../assets/img/ourWorks/2.PNG';
import pictureImage2 from '../assets/img/ourWorks/3.PNG';

import '../assets/css/our-works.css';


const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
}

const slideImages = [
    {
        url: pictureImage0,
        caption: 'Композиция из красных роз'
    },
    {
        url: pictureImage1,
        caption: 'Композиция из оранжевых цветов'
    },
    {
        url: pictureImage2,
        caption: 'Композиция из нежных белых и сиреневых цветов'
    },
];

export default function OurWorks() {
    const [slidesToShow, setSlidesToShow] = useState(window.innerWidth <= 1000 ? 2 : 3);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='our-works'>
            <h1>НАШИ РАБОТЫ</h1>
            <div className="slide-container">
                <Slide 
                    slidesToShow={slidesToShow}
                    {...properties}>
                    {slideImages.map((slideImage, index)=> (
                        <div key={index}>
                        <div className="slider-image" style={{ 'backgroundImage': `url(${slideImage.url})` }} />
                        <span className="slider-span" >{slideImage.caption}</span>

                        </div>
                    ))} 
                </Slide>
            </div>
        </div>
    );
}