import vkIcon from '../assets/img/contacts/vk-icon.png';
import tgIcon from '../assets/img/contacts/telegram-black-icon.webp';
import '../assets/css/contacts.css';

export default function Contacts() {
    return (
        <div id="contacts" className="contacts">
            <h1>КОНТАКТЫ</h1>
            <div className="contacts-blocks">
                <div className="contact vk">
                    <img src={ vkIcon } alt="" />
                    <a href="https://vk.com/blooming__art" target="_blank">https://vk.com/blooming__art</a>
                </div>
                <div className="contact telegram">
                    <img src={ tgIcon } alt="" />
                    <a href="https://t.me/blooming_art" target="_blank">https://t.me/blooming_art</a>
                </div>
            </div>        
        </div>
    )
}
