import NavigationBar from '../components/NavBar.jsx';

import '../assets/css/header.css';
import bgImage from '../assets/img/mainPage.jpg'

export default function Header() {
    return (
        <>
            <header>
                <NavigationBar />
            </header>
            <div className="bg-image">
                <img src={ bgImage } alt="" />
            </div>
            <div className="logo-head" />
        </>
    );
}