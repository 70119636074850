import Parser from 'html-react-parser';

export default function Picture({ img, name, description, style="" }) {
    return (
        <div className="picture" style={ style }>
            <div className="image">
                <img src={img} alt="" />
            </div>
            <div className="text-content">
                <div className="name">
                    <strong>
                    {name}
                    </strong>
                </div>
                <div className="description">
                    {Parser(description)}
                </div>
            </div>
        </div>
    )
}