import '../assets/css/technology.css';

import pictureImage0 from '../assets/img/IMG_4693.JPG';
import pictureImage1 from '../assets/img/IMG_4694.JPG';

export default function Technology() {
    return (
        <div className="technology">
            <div className="container">
                <div className="photo photo0"></div>
                <div className="subphoto"></div>

                <div className="description">
                    Стабилизация цветов в глицерине – это процесс консервации растений, 
                    позволяющий сохранить их естественную красоту и свежесть на долгие годы. 
                    Создание картин из стабилизированных цветов нашей команды состоит из 
                    таких основных этапов: подготовка цветов и глицерина, 
                    добавление красителя (по желанию), замачивание цветов 
                    (этот этап длится примерно две недели в зависимости от цветка), вынимание и сушка.    
                </div>
            </div>
            <div className="container">
                <div className="description">
                    Теперь, когда у нас есть стабилизированные цветы, 
                    мы используем их для создания картины. 
                    Для этого мы подбираем рамку, на которой будем составлять компазицию, 
                    и аккуратно распологаем цветы на её поверхности, 
                    создавая уникальный дизайн по вашему желанию. 
                    После того как цветы расположены закрепляем их местоположение. 
                    Получившуюся картину отправляем удобным для вас способом доставки.   
                </div>
                
                <div className="photo photo1"></div>
                <div className="subphoto"></div>
            </div>
        </div>
    );
}